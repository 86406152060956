import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import AudioPlayer from 'react-h5-audio-player';


const SecondPage = () => (
  <Layout>
    <Seo title="About Us" />
    
    
    <Container className="my-4">
    <h1>Podcasts</h1>
      <Row>
      <Col md={5}>
          <p>Podcasts can seem daunting. But I'm here to hand-hold you through the process, to ensure your podcast is a success. A podcast can do a lot of for your business. It can help you elevate your professionalism, sell online-courses and extended your overall advertising reach.</p>
          <p>
            All you need to start a podcast is a microphone and your voice. That's it. As you grow, the equipment can grow as well. But the important part is to focus on your message. I'll worry about the technical aspects. 
          </p>
          <p><strong>You can create a podcast. I'm here to help.</strong></p>
          <Link to="/contact/" class="btn btn-primary">Let's Connect & Work Together</Link>
        </Col>
        <Col md={7}>
          <div className="videoWrapper">
          <iframe src="https://player.vimeo.com/video/443122551?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" width="100%" height="100%" title="Vimeo" allowfullscreen></iframe>
          </div>
          
        </Col>
        
      </Row>
      <hr />
      <h2 className="pb-5 text-center">Listen to a few of the podcasts I've produced.</h2>
      
      <Row>
        <Col lg={6}>
        <Row> 
            <Col md={5}>
              <p>
              <StaticImage 
              src="../images/paprika-southern-cover.jpg"
              alt="Paprika Southern Cover"
              placeholder="blurred"
              width="500"
            />
            </p>
            </Col>
            <Col md={7}>
            <p>
              <AudioPlayer
          src="https://episodes.buzzsprout.com/6nca1xlj4oazhe4ddzlymt6dsmq6?"
          customAdditionalControls={[]}
          customVolumeControls={[]}
          showJumpControls={false}
          layout="horizontal-reverse"
          />
              </p>
              <p>PS: The Paprika Southern Podcast inspires creative and intentional living through biweekly episodes sharing lifestyle content including interviews with creative entrepreneurs, and more. </p>
              

            </Col>
          </Row>
        </Col>
        <Col lg={6}>
        <Row> 
            <Col lg={5}>
              <p>
              <StaticImage 
              src="../images/kia-orana-podcast-cover.jpg"
              alt="Kia Orana Podcast Image"
              placeholder="blurred"
              width="500"
            />
            </p>
            </Col>
            <Col lg={7}>
              <p>
              <AudioPlayer
          src="https://www.buzzsprout.com/1121474/4699949-captain-brendon-nicholas-dawn-breaker-game-fishing-charters.mp3"
          customAdditionalControls={[]}
          customVolumeControls={[]}
          showJumpControls={false}
          layout="horizontal-reverse"
          />
              </p>
              <p>The Kia Orana Podcast is a show about what you'll find, and what you won't find, in the Cook Islands. Podcast is recorded via zoom and produced weekly.</p>
              

            </Col>
          </Row>
        
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={8}>
        <div className="videoWrapper">
          <iframe src="https://player.vimeo.com/video/289100387?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" width="100%" height="100%" title="Vimeo" allowfullscreen></iframe>
          </div>
        </Col>
        <Col lg={4}>
          <h3>After the Leap</h3>
          <p>The podcast looked at individuals who left corporate jobs and started their own businesses. This episode fetured Gwen McClure, talking about her experience leaving a high-paying job in San Francisco for a different pace of life.</p>
        </Col>
      </Row>
      <hr />


      
      <Row>
        <Col lg={8}>
        <div className="videoWrapper">
          <iframe src="https://player.vimeo.com/video/339387576?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" width="100%" height="100%" title="Vimeo" allowfullscreen></iframe>
          </div>
        </Col>
        <Col lg={4}>
          <h3>The Digestive Podcast</h3>
          <p>The Digestive Podcast featured functional nutritionist, Frances Holmes. In this limited series, she focused on gut health. While at first it might sound hard to hear, the message is clear and clean. She uses the limited series to help expand her public speaking reach.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SecondPage
